import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { UserProvider } from './src/components/user-context/UserContext';
import NotificationProvider from './src/components/notification/Notification';

export const WrapRootElement = ({ element }) => {
    return <UserProvider>
        <NotificationProvider>
            {element}
            
            <Helmet htmlAttributes={
                {
                    lang: 'en'
                }
            }>
                <meta charSet="utf-8"/>
                <title>Baking Soda Labs</title>
            </Helmet>
        </NotificationProvider>
    </UserProvider>;
};

WrapRootElement.propTypes = {
    element: PropTypes.node.isRequired
};