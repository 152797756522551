import React, { createContext, Children, cloneElement, useState, useEffect } from 'react';
import NotificationComponent from './notification-component/NotificationComponent';
import { createPortal } from 'react-dom';
import classnames from 'classnames';

import './Notification.scss';

const NOTIFICATION_TYPES = {
    INFO: 'info',
    WARNING: 'warning',
    CRITICAL: 'critical',
    SUCCESS: 'success'
};

const NotificationSharedContext = createContext({
    notify: () => { return; }
});

const NotificationProvider = ({ children }) => {
    const [currentNotification, setCurrentNotification] = useState();
    const [targetElem, setTargetElem] = useState(null);

    const addNotification = (notificationObj) => {
        setCurrentNotification(notificationObj);
    };

    useEffect(() => {
        setTargetElem(document.getElementsByTagName('body')[0]);
    }, []);

    const Notification = currentNotification ? <NotificationComponent {...currentNotification} />  : null;

    // eslint-disable-next-line no-prototype-builtins
    const notificationType = currentNotification?.hasOwnProperty('type') ? currentNotification.type : null;

    const notificationWrapperClassnames = classnames({
        notificationWrapper: true,
        'notificationInfo': !notificationType || notificationType === NOTIFICATION_TYPES.INFO,
        'notificationError': notificationType && notificationType === NOTIFICATION_TYPES.CRITICAL,
        'notificationWarn': notificationType && notificationType === NOTIFICATION_TYPES.WARNING,
        'notificationSuccess': notificationType && notificationType === NOTIFICATION_TYPES.SUCCESS
    });

    const NotificationWrapper = (
        <div className={notificationWrapperClassnames}>
            {Notification}
        </div>
    );

    const NotificationWrapperComponent = targetElem ? (createPortal(
        (NotificationWrapper)
        , targetElem)) : NotificationWrapper;

    return (
        <NotificationSharedContext.Provider
            value={{
                notify: addNotification
            }}
        >
            {NotificationWrapperComponent}
            {children}
        </NotificationSharedContext.Provider>
    );
};

export default NotificationProvider;

export const NotificationConsumer = (props) => {
    const extraProps = {};
    /* eslint-disable no-unused-vars */
    for (let key in props) {
        if (key !== 'children') {
            extraProps[key] = props[key];
        }
    }
    /* eslint-enable no-unused-vars */

    return (
        <NotificationSharedContext.Consumer>
            {({ notify }) => {
                const children = props.children;
                return Children.map(children, (child) => {
                    return cloneElement(child, Object.assign(extraProps, { notify }));
                });
            }}
        </NotificationSharedContext.Consumer>
    );
};

export const NotificationContext = NotificationSharedContext;
