import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";
import './src/styles/main.scss'
import './src/styles/icons.scss'

import { WrapRootElement } from './wrap-root-element';
import Authenticator from "./src/services/Authenticator";

export const wrapRootElement = ({ element }) => {
    return WrapRootElement({ element });
};

export const onInitialClientRender = () => {
    Authenticator.init();
    const gatsbyWrapperDom = document.getElementById('gatsby-focus-wrapper');

    if (gatsbyWrapperDom) {
        gatsbyWrapperDom.setAttribute('id', 'pcharity-focus-wrapper');
    }
};
